import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { months, unifiedWeb } from 'src/app/service/app.constant';
import { DatalayerService } from 'src/app/service/datalayer.service';

@Component({
  selector: 'app-header-signup',
  templateUrl: './header-signup.component.html',
  styleUrls: ['./header-signup.component.css']
})
export class HeaderSignupComponent implements OnInit {

  studentportal = environment.studentportal;
  utm:any = [];

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private _datalayer: DatalayerService) {}
  public unifiedWeb  = unifiedWeb
  ngOnInit(): void {
    if(this.router.url.split('?')[1] != undefined) {
      this.utm = '?'+this.router.url.split('?')[1]+'&page='+this.router.url.split('?')[0].slice(1);
    } else if(this.router.url != '/') {
      this.utm = '?page='+this.router.url.split('?')[0].slice(1);
    } else if(this.router.url == '/'){
      this.utm = '?page=signup'
    }
    if(sessionStorage.getItem("isFromBajajFinservAppInApp") === "true" && !this.utm?.includes("utm_source=BajajFinserv")) {
      this.utm += "&utm_source=BajajFinserv&utm_medium=AppInApp"
    }
  }

  callNow(){
    this._datalayer.webEngageEvent({
      'event': 'call_icon_clicked',
      'page_url': window.location.href,
      'button_name': 'Call_Now',
      'button_section': "Header",
      'exp_version': "A",
      'lead_category': this.route.snapshot.queryParamMap.get('gclid') ? "Marketing" : "Organic",
      'utm_source': this.route.snapshot.queryParamMap.get('utm_source'),
      'month': months[new Date().getMonth()]
    })
  }
  
}

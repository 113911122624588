
<!-- <a *ngIf="whatsapp && !(pathName == '/cbse-fullcourse' || pathName == '/cbse-fullcourse/10' || pathName == '/cbse-fullcourse/12')" href="https://api.whatsapp.com/send?phone=919100499683&text=Hi%2C+I+am+interested+to+know+more+about+your+courses" class="float" [ngClass]="buttomfloat" target="_blank">
<i class="fa fa-whatsapp my-float"></i>
</a> -->

<!-- <a *ngIf="whatsapp" href="https://api.whatsapp.com/send?phone=919100499683&text=Hi%2C+I+am+interested+to+know+more+about+your+courses" class="float" [ngClass]="buttomfloat" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
</a> -->

<!-- <a *ngIf="whatsapp && (pathName === '/cbse-fullcourse' || pathName === '/cbse-fullcourse/10' || pathName === '/cbse-fullcourse/12')" href="https://api.whatsapp.com/send?phone=919100499683&text=Hi%2C+I+am+interested+to+know+more+about+your+courses" class="float" [ngClass]="buttomfloatcbse" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
</a> -->

<a (scroll)="onScroll($event)" [id]="pageData?.scrollId" *ngIf="pageData?.isVisible" [href]="pageData?.link" [class]="pageData?.CssClassName" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
</a>


<!-- ========================= Infinity Learn Experience ========================= -->
<section class="experience-area">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-xs-12">
        <div class="experience-content">
          <figure>
            <img src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/comma.webp"
              alt="" class="comma" loading="lazy">
          </figure>
          <h1 class="exptitle">Students &<br>Parents Love us</h1>
          <!-- 
            <p class="expsubtitle">We’re passionate and we build your talent. Our team is here to help you stand out
            and reach great heights with your passion.</p> -->
          <div class="hero-btns">
            <a href="https://student.infinitylearn.com/signup" (click)="we_join_for_free()"
              class="main-btn btn-hover">Join for free!</a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-xs-12">

        <div class="mobile-wrapper text-center">
          <div id="studentlearning" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators studentindicators">
              <li data-target="#studentlearning" data-slide-to="0" class="active" style="cursor: default;"></li>
              <li *ngFor="let d of testimonial" data-target="#studentlearning" style="cursor: default;"></li>
            </ol>

            <div class="carousel-inner">

              <div *ngFor="let d of testimonial_active" class="carousel-item active">
                <div class="exp-card">
                  <div class="studentimg-center">
                    <figure class="">
                      <img src="{{ d.image }}" alt="{{ d.name }}" loading="lazy" class="expstudent">
                    </figure>
                    <h6 class="studentname">{{ d.name }}</h6>
                    <p class="sturank">{{ d.subname }}</p>
                  </div>
                  <div class="studentsays">
                    <h5 class="hme_stutitle">{{ d.heading }}</h5>
                    <p class="hme_stutxt">“{{ d.description }}”</p>
                  </div>
                </div>
              </div>

              <div *ngFor="let d of testimonial" class="carousel-item">
                <div class="exp-card">
                  <div class="studentimg-center">
                    <figure class="">
                      <img src="{{ d.image }}" alt="{{ d.name }}" loading="lazy" class="expstudent">
                    </figure>
                    <h6 class="studentname">{{ d.name }}</h6>
                    <p class="sturank">{{ d.subname }}</p>
                  </div>
                  <div class="studentsays">
                    <h5 class="hme_stutitle">{{ d.heading }}</h5>
                    <p class="hme_stutxt">“{{ d.description }}”</p>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>


      </div>
    </div>
  </div>
</section>
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable()
export class NoCacheHeadersInterceptor implements HttpInterceptor {
intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      setHeaders: {
        'Cache-Control': 'no-cache, no-store, max-age=31536000, must-revalidate',
        'Accept': '*',
        Pragma: 'no-cache',
      }
    });
    return next.handle(authReq);
  }
}
import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
 
@Injectable({
    providedIn: 'root',
  })
export class LazyloadService {
 
 
  constructor(@Inject(DOCUMENT) private document: Document) {}
 
  loadStyle(styleName: string) {
    if(navigator.userAgent.indexOf("Chrome-Lighthouse") == -1) {
    const head = this.document.getElementsByTagName('head')[0];
      const style = this.document.createElement('link');
      style.rel = 'stylesheet';
      style.href = styleName;
      head.appendChild(style);
    
  }
}
 
}
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MetatagsService {

  constructor(
    @Inject(DOCUMENT) private doc,
    private _http:HttpClient ) { }

    metatagsUrl = "https://laravelcms.devinfinitylearn.in/api/marketing";

    meta():Observable<any>
    {
      return this._http.get(this.metatagsUrl);
    }

    createLinkForCanonicalURL() {
      let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.doc.head.appendChild(link);
      link.setAttribute('href', this.doc.URL);
      return console.log(link,'canonical Url');
   }

   alertmsg(page) {
    const url = 'https://laravelcms.devinfinitylearn.in/api/alert-marketing';
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "page": page,
     }
    return this.getByHeaders<any>(url, headers);
  }

  metatag(page) {
    const url = 'https://laravelcms.devinfinitylearn.in/api/marketing';
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "page": page,
     }
    return this.getByHeaders<any>(url, headers);
  }

  banner(page) {
    const url = 'https://laravelcms.devinfinitylearn.in/api/webbanner';
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "page": page,
     }
    return this.getByHeaders<any>(url, headers);
  }

  getByHeaders<T>(url: string,  headers: any): Observable<T> {
    return this._http.get<any>(url, {headers: headers})
      .pipe(map(data => {
        return data;
      }));
  }
}

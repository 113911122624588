<!-- master class faculty starts -->
<section class="il-masterclsapad">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-12 col-12">
        <div class="section-head">
          <h1 class="sec-title {{colorBlue ? 'whysc_maintitle': ''}}">Masterclass Faculty</h1>
          <p class="sec-text">
            Access to India's best teachers with a record of producing top
            rankers year on year.
          </p>
        </div>
      </div>
      <div class="col-md-12 col-lg-12 col-lg-12">
        <div id="masterclassfaculty" class="owl-carousel masterfacshow">
          <!-- faculty 1 -->
          <!-- <div class="item">
            <div class="mas-fundbox">
              <div class="pr-fundimgsec">
                <figure class="mb-0">
                  <img
                    src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/faculty/Supreet.webp"
                    alt="" class="pr-fundimg" loading="lazy" />
                </figure>
              </div>
              <div class="mas-fundcontent">
                <div class="mastercardflex">
                  <div>
                    <h5 class="mas-teacher">Supreet Singh</h5>
                  </div>
                  <div>
                    <h6 class="mas-sub-phy">Physics</h6>
                  </div>
                </div>
                <div class="mas-facview">
                  <span class="mas-nshow"><i class="star on"></i>4.9</span>
                  <span class="mas-nrating">19.7k Ratings</span>
                </div>
                <p class="mas-tcontent">
                  Supreet has 9+ years of teaching experience in Physics for JEE
                  & NEET. He mentored more than 5000 students and produced many
                  double digit AIRs.
                </p>
                <ul class="mas-specific">
                  <li>9+ yrs Experienced</li>
                  <li>High Ratings</li>
                </ul>
              </div>
            </div>
          </div> -->
          <!-- faculty 2 -->
          <div class="item">
            <div class="mas-fundbox">
              <div class="pr-fundimgsec">
                <figure class="mb-0">
                  <img
                    src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/faculty/Vibhati.webp"
                    alt="" class="pr-fundimg" loading="lazy" />
                </figure>
              </div>
              <div class="mas-fundcontent">
                <div class="mastercardflex">
                  <div>
                    <h5 class="mas-teacher">Vibhati Jain</h5>
                  </div>
                  <div>
                    <h6 class="mas-sub-phy">Physics</h6>
                  </div>
                </div>
                <div class="mas-facview">
                  <span class="mas-nshow"><i class="star on"></i>4.6</span>
                  <!-- <span class="mas-nrating">3.7 Ratings</span> -->
                </div>
                <p class="mas-tcontent">
                  Vibhati has 6+ years of teaching experience in Physics for
                  NEET and has mentored more than 10000 students. A majority of
                  her students have secured 99-100% scores.
                </p>
                <ul class="mas-specific">
                  <li>Taught 10000+ students</li>
                  <li>High Ratings</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- faculty 3 -->
          <div class="item">
            <div class="mas-fundbox">
              <div class="pr-fundimgsec">
                <figure class="mb-0">
                  <img
                    src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/faculty/prakhar.webp"
                    alt="" class="pr-fundimg" loading="lazy" />
                </figure>
              </div>
              <div class="mas-fundcontent">
                <div class="mastercardflex">
                  <div>
                    <h5 class="mas-teacher">Prakhar Verma</h5>
                  </div>
                  <div>
                    <h6 class="mas-sub-phy">Physics</h6>
                  </div>
                </div>
                <div class="mas-facview">
                  <span class="mas-nshow"><i class="star on"></i>4.6</span>
                  <!-- <span class="mas-nrating">7.8k ratings</span> -->
                </div>
                <p class="mas-tcontent">
                  Prakhar completed his B.Tech & M.Tech from IIT (Kharagpur) in
                  chemical engineering. He has 8+ years of experience in
                  teaching Physics for JEE and has produced many AIRs.
                </p>
                <ul class="mas-specific">
                  <li>8 yrs Experienced</li>
                  <li>High Ratings</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- faculty 4 -->
          <div class="item">
            <div class="mas-fundbox">
              <div class="pr-fundimgsec">
                <figure class="mb-0">
                  <img
                    src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/faculty/sarfaraz.webp"
                    alt="" class="pr-fundimg" loading="lazy" />
                </figure>
              </div>
              <div class="mas-fundcontent">
                <div class="mastercardflex">
                  <div>
                    <h5 class="mas-teacher">Shaik Sarfaraz</h5>
                  </div>
                  <div>
                    <h6 class="mas-sub-phy">Chemistry</h6>
                  </div>
                </div>
                <div class="mas-facview">
                  <span class="mas-nshow"><i class="star on"></i>4.7</span>
                  <!-- <span class="mas-nrating">8.2k Ratings</span> -->
                </div>
                <p class="mas-tcontent">
                  Sarfaraz has completed his Ph.D in chemistry from Osmania
                  University and has 18+ years of teaching experience in
                  Chemistry for JEE & NEET. He has produced many double digit
                  AIRs.
                </p>
                <ul class="mas-specific">
                  <li>18 yrs Experienced</li>
                  <li>High Ratings</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- faculty 5 -->
          <div class="item">
            <div class="mas-fundbox">
              <div class="pr-fundimgsec">
                <figure class="mb-0">
                  <img
                    src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/faculty/suchita.webp"
                    alt="" class="pr-fundimg" loading="lazy" />
                </figure>
              </div>
              <div class="mas-fundcontent">
                <div class="mastercardflex">
                  <div>
                    <h5 class="mas-teacher">Suchita Mukhopadhyay</h5>
                  </div>
                  <div>
                    <h6 class="mas-sub-bio">Botany</h6>
                  </div>
                </div>
                <div class="mas-facview">
                  <span class="mas-nshow"><i class="star on"></i>4.6</span>
                  <!-- <span class="mas-nrating">8.9k Ratings</span> -->
                </div>
                <p class="mas-tcontent">
                  Suchita has completed her M.Tech from IIT-ISM (Dhanbad) and
                  has 8+ years of experience in teaching Botany and produced
                  many double digit AIRs and many selections into AIIMS, JIPMER,
                  BMC, etc.
                </p>
                <ul class="mas-specific">
                  <li>8 yrs Experienced</li>
                  <li>High Ratings</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- faculty 6 -->
          <div class="item">
            <div class="mas-fundbox">
              <div class="pr-fundimgsec">
                <figure class="mb-0">
                  <img
                    src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/faculty/mythili.webp"
                    alt="" class="pr-fundimg" loading="lazy" />
                </figure>
              </div>
              <div class="mas-fundcontent">
                <div class="mastercardflex">
                  <div>
                    <h5 class="mas-teacher">Mythili Abburi</h5>
                  </div>
                  <div>
                    <h6 class="mas-sub-bio">Zoology</h6>
                  </div>
                </div>
                <div class="mas-facview">
                  <span class="mas-nshow"><i class="star on"></i>4.5</span>
                  <!-- <span class="mas-nrating">7.5k Ratings</span> -->
                </div>
                <p class="mas-tcontent">
                  Mythili completed B.Ed from Bangalore University and has 15+
                  years of teaching experience in Zoology. She has produced top
                  10 rankers in KCET. Her mentoring was also crucial for the top
                  10 ranks in EAMCET for 3 consecutive years.
                </p>
                <ul class="mas-specific">
                  <li>15 yrs Experienced</li>
                  <li>High Ratings</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- faculty 7 -->
          <div class="item">
            <div class="mas-fundbox">
              <div class="pr-fundimgsec">
                <figure class="mb-0">
                  <img
                    src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/faculty/Basheer.webp"
                    alt="" class="pr-fundimg" loading="lazy" />
                </figure>
              </div>
              <div class="mas-fundcontent">
                <div class="mastercardflex">
                  <div>
                    <h5 class="mas-teacher">Basheer Shaik</h5>
                  </div>
                  <div>
                    <h6 class="mas-sub-phy">Mathematics</h6>
                  </div>
                </div>
                <div class="mas-facview">
                  <span class="mas-nshow"><i class="star on"></i>4.6</span>
                  <!-- <span class="mas-nrating">8.6k Ratings</span> -->
                </div>
                <p class="mas-tcontent">
                  Basheer is a double-gold medalist in Mathematics with 18+
                  years of teaching experience in Mathematics. He has produced
                  many single and double digit ranks such as 5, 18 and more.
                </p>
                <ul class="mas-specific">
                  <li>18 yrs Experienced</li>
                  <li>High Ratings</li>
                </ul>
              </div>
            </div>
          </div>
          <!-- faculty 8 -->
          <div class="item">
            <div class="mas-fundbox">
              <div class="pr-fundimgsec">
                <figure class="mb-0">
                  <img
                    src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/faculty/kiran.webp"
                    alt="" class="pr-fundimg" loading="lazy" />
                </figure>
              </div>
              <div class="mas-fundcontent">
                <div class="mastercardflex">
                  <div>
                    <h5 class="mas-teacher">Kiran Choudhary</h5>
                  </div>
                  <div>
                    <h6 class="mas-sub-bio">Botany</h6>
                  </div>
                </div>
                <div class="mas-facview">
                  <span class="mas-nshow"><i class="star on"></i>4.6</span>
                  <!-- <span class="mas-nrating">7.1k Ratings</span> -->
                </div>
                <p class="mas-tcontent">
                  Kiran has 9 years of teaching experience and has successfully
                  mentored more than 5000 students for NEET. Many of her
                  students have secured Top All India Ranks.
                </p>
                <ul class="mas-specific">
                  <li>9 yrs Experienced</li>
                  <li>High Ratings</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- master class faculty ends -->
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DatalayerService {

  constructor(private _http:HttpClient) { }
  
    public webEngageEvent(eventValue){
      //@ts-ignore
      window?.sendGAEvent?.call && window?.sendGAEvent(eventValue)
    }
}

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, Observer, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as AWS from 'aws-sdk';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';
import { lsqNames } from './lsq-sources-data/lsq-source';
import { FirebaseService } from './firebase/firebase.service';
import { product_id, tenant_id } from './app.constant';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class UserRegistrationService {
  
  constructor(private _http:HttpClient, private firebaseService: FirebaseService, private route: ActivatedRoute,) { 
    // this._http.get("http://api.ipify.org/?format=text").subscribe((res:any)=>{
    //   this.ipAddress = res.ip;
    //   this._http.get(`https://ipapi.co/157.47.46.76/json`).subscribe((resp:any)=>{
    //     this.city = resp?.city;
    //     this.state = resp?.region;
    //   });
    // });
  }

  appUrl = environment.webapi;
  gateWayUrl = environment?.gateWayUrl;
  gateWayOtpUrl = environment?.gateWayOtpUrl;
  isExitingUser:boolean = false;
  ipAddress:any;
  state:any;
  city:any;
  lsqNames:any;
  whatsappConsent:boolean = false;
  regionalDetails:any;
  newUserDetails:any;
  headers= new HttpHeaders()
    .set('X-Tenant', 'infinitylearn');

  getGrades(){
    const headers = { 
      "X-Page": "WEB_IL_SITE",
      "X-Product": "1", 
      "product-id": product_id,
      "tenant-id": tenant_id,
      "platform":"web"
    }
    return this._http.get(`${this.gateWayUrl}api/v1/grade?productId=${product_id}`,{ 'headers': headers }).pipe(catchError(this.errorHandler));
  }

  verifyPhone(request: any):Observable<any> {
    const headers = { 
      "tenant-id":tenant_id,
      "product-id":product_id,
      "platform":"web"
     }
    this.checkUser(request);
    return this._http.post(`${this.gateWayUrl}api/v1/user/learn/verify-phone`,request,{ 'headers': headers }).pipe(catchError(this.errorHandler));
  }

  sendOtp(request: any):Observable<any> {
    this.whatsappConsent = request?.whatsappConsent ? request?.whatsappConsent : false;
    const headers = { 
      "X-Tenant": "infinitylearn",
      "accept": "application/json",
      "Content-Type": "application/json",
      "platform":"web"
     }
     request.tenant_id = tenant_id;
     request.product_id = product_id;
     request.isd_code = request?.isdCode;
     this.newUserDetails = request;
     delete request.isdCode;
     return this._http.post(`${this.gateWayOtpUrl}api/generateOTP`,request,{ 'headers': headers }).pipe(catchError(this.errorHandler));
  }

  validateOTP(request: any):Observable<any> {
    const headers = { 
      "tenant-id":tenant_id,
      "product-id":product_id,
      "platform":"web"
     }
     let body:any;
     if(!this.isExitingUser){
    body =  {
        isd_code: request?.isdCode,
        phone: request?.phone,
        otp: request?.userOtp,
        first_name:  this.newUserDetails?.firstName, 
        last_name: this.newUserDetails?.lastName, 
        grade_id: this.newUserDetails?.gradeId ? this.newUserDetails?.gradeId : undefined, 
        whatsapp_consent: this.newUserDetails?.whatsappConsent ? this.newUserDetails?.whatsappConsent : false,
      }
     } else {
      body =  {
        isd_code: request?.isdCode,
        phone: request?.phone,
        otp: request?.userOtp,
      }
     }
    return this._http.post(`${this.gateWayUrl}api/v1/user/learn/login-with-otp`,body,{ 'headers': headers }).pipe(catchError(this.errorHandler));
  }
  getUserDto(): Observable<any> { 
    let token = localStorage.getItem('token');
    const headers = {
      "product-id": product_id,
      "tenant-id": tenant_id,
      "platform":"web",
      "Authorization":`Bearer ${token}`
    }
    return this._http.get(`${this.gateWayUrl}api/v1/user/learn/dto`,{ 'headers': headers }).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return new Observable((observer: Observer<any>) => {
      observer.error(error);
    });
  }

  checkUser(request: any){
    const headers = { 
      "tenant-id":tenant_id,
      "product-id":product_id,
      "platform":"web"
     }
    this._http.post(`${this.gateWayUrl}api/v1/user/learn/verify-phone`,request,{ 'headers': headers }).subscribe((resp:any)=>{
      console.log(resp);
      this.isExitingUser = resp?.existingUser;
    });
  }

  callTrackingAPI(userId, payload) {
    const url = this.appUrl + `/api/tracking/${userId}/userTracking`;
    if(environment?.isSqsShow){
      this.getStudentDetails(userId,payload?.data);
    }
    let headers;
    if(payload?.data?.page_id) {
      headers = { 
      "Authorization": "Bearer "+localStorage.getItem('token'),
      "X-Page_id": payload?.data?.page_id || "WEB_IL_SITE",
      "X-Platform": "web",
      "X-Product": "1",
      }
    
    }
      else {
        headers = { 
        "Authorization": "Bearer "+localStorage.getItem('token'),
        "X-Page": payload?.data?.page || "WEB_IL_SITE",
        "X-Platform": "web",
        "X-Product": "1",
        }
      }

    return this.getByHeaders<any>(url, payload, headers);    
  }

  getStudentDetails(userId,LSQPayload){
    this.firebaseService.getRemoteConfig("lsq_data_config").then((lsqData)=>{
    this.lsqNames = lsqData || lsqNames;
    let lsqSource = this.lsqNames.find(p=>p.Page == window.location.pathname)?.Source;
    let token = localStorage.getItem('token');
    const headers = {
      "product-id": product_id,
      "tenant-id": tenant_id,
      "platform":"web",
      "Authorization":`Bearer ${token}`
    }
    let url = `${this.gateWayUrl}api/v1/user/learn/dto`;
    this._http.get(url,{ 'headers': headers }).pipe(catchError(this.errorHandler)).subscribe((resp:any)=>{
      console.log(resp);
      resp = resp?.[0];
      this.firebaseService.getSqsValue().then((firebaseConfig)=>{
        console.log(LSQPayload)
        let fields = {};
        if(lsqSource === 'Marketing Pages'){
          let activityFields = {
            mx_Custom_1 : LSQPayload?.utm_url?.slice(0,190) || undefined,
            mx_Custom_2 : LSQPayload?.utm_source || undefined,
            mx_Custom_3 : LSQPayload?.utm_campaign || undefined,
            mx_Custom_4 : LSQPayload?.utm_medium || undefined,
            mx_Custom_5 : LSQPayload?.utm_content || undefined,
            mx_Custom_6 : LSQPayload?.utm_id || undefined,
            mx_Custom_7 : LSQPayload?.utm_term|| undefined,
            mx_Custom_8 : LSQPayload?.page || undefined,
            mx_Custom_11:  Number(LSQPayload?.grade || resp?.grade?.gradeId) || undefined,
            mx_Custom_12:  LSQPayload?.exam || resp?.exams?.[0]?.name?.replace(/[^a-z]/ig,'').toUpperCase() || undefined,
            mx_Custom_13: document.referrer || undefined,
            mx_Custom_14: window.location.pathname,
            mx_Custom_15: this.route.snapshot.queryParamMap.get('gclid') || this.route.snapshot.queryParamMap.get('fbclid') ? 'Paid' : 'Organic'
          }

          if(!this.isExitingUser){
          let marketingFields = {
              mx_Custom_1 : LSQPayload?.utm_url?.slice(0,190) || undefined,
              mx_Custom_2 : LSQPayload?.utm_source || undefined,
              mx_Custom_3 : LSQPayload?.utm_campaign || undefined,
              mx_Custom_4 : LSQPayload?.utm_medium || undefined,
              mx_Custom_5 : LSQPayload?.utm_content || undefined,
              mx_Custom_6 : LSQPayload?.utm_id || undefined,
              mx_Custom_7 : LSQPayload?.utm_term|| undefined,
              mx_Custom_8 : LSQPayload?.page || undefined,
              mx_Custom_10 : LSQPayload?.page === 'score'? LSQPayload?.page : undefined,
              mx_GCLID: LSQPayload?.GCLID || undefined,
              mx_Actual_source_URL: LSQPayload?.utm_url?.slice(0,190) || undefined,
              mx_Country : this.regionalDetails?.country_name || undefined,
              mx_State: LSQPayload?.state || this.regionalDetails?.region || undefined,
              mx_City: LSQPayload?.city || this.regionalDetails?.city || undefined,
              // SourceReferrerURL : document.referrer || undefined,
              mx_Grade: Number(LSQPayload?.grade || resp?.grade?.gradeId) || undefined,
              mx_Exam: LSQPayload?.exam || resp?.exams?.[0]?.name?.replace(/[^a-z]/ig,'').toUpperCase() || undefined,
              mx_Primary_Target_Exam: LSQPayload?.exam || resp?.exams?.[0]?.name?.replace(/[^a-z]/ig,'').toUpperCase() || undefined,
              EmailAddress: LSQPayload?.email || undefined,
              SourceCampaign : LSQPayload?.SourceCampaign || LSQPayload?.utm_campaign || undefined,
              SourceMedium : LSQPayload?.utm_medium || undefined,
              SourceContent : LSQPayload?.utm_content || undefined,
              mx_Business_Unit: 'Learn'
            }
            let MarketingPayload = {
              "ActivityDateTime": moment().utc().format("YYYY-MM-DD HH:mm:ss"),
              "Fields": marketingFields,
              "FirstName": resp?.firstName,
              "LastName": resp?.lastName,
              "environment": environment?.branch,
              "phone": resp?.phone,
              "Source": "Marketing Pages",
              "productId": 1,
              "whatsappConsent": !this.isExitingUser ? this.whatsappConsent : undefined,
              "type": "Lead",
            }
            this.sendSQSMsg(firebaseConfig?.access_key, firebaseConfig?.secret_key, firebaseConfig,MarketingPayload);

          }
          let firebasePayload = {
            "ActivityDateTime": moment().utc().format("YYYY-MM-DD HH:mm:ss"),
            "ActivityEvent": 255,
            "Fields": activityFields,
            "FirstName": resp?.firstName,
            "LastName": resp?.lastName,
            "environment": environment?.branch,
            "phone": resp?.phone,
            "productId": 1,
            "type": "Activity",
          }
          if(!this.isExitingUser){
            setTimeout(()=>{
              this.sendSQSMsg(firebaseConfig?.access_key, firebaseConfig?.secret_key, firebaseConfig,firebasePayload)
            },1500)
          } else {
            this.sendSQSMsg(firebaseConfig?.access_key, firebaseConfig?.secret_key, firebaseConfig,firebasePayload)
          }

        } else {
          fields = {
            mx_Custom_1 : LSQPayload?.utm_url?.slice(0,190) || undefined,
            mx_Custom_2 : LSQPayload?.utm_source || undefined,
            mx_Custom_3 : LSQPayload?.utm_campaign || undefined,
            mx_Custom_4 : LSQPayload?.utm_medium || undefined,
            mx_Custom_5 : LSQPayload?.utm_content || undefined,
            mx_Custom_6 : LSQPayload?.utm_id || undefined,
            mx_Custom_7 : LSQPayload?.utm_term|| undefined,
            mx_Custom_8 : LSQPayload?.page || undefined,
            mx_GCLID: LSQPayload?.GCLID || undefined,
            SourceCampaign : LSQPayload?.SourceCampaign || LSQPayload?.utm_campaign || undefined,
            SourceMedium : LSQPayload?.utm_medium || undefined,
            SourceContent : LSQPayload?.utm_content || undefined,
            mx_Actual_source_URL: LSQPayload?.utm_url?.slice(0,190) || undefined,
            mx_Country : this.regionalDetails?.country_name || undefined,
            mx_State: LSQPayload?.state || this.regionalDetails?.region || undefined,
            mx_City: LSQPayload?.city || this.regionalDetails?.city || undefined,
            // SourceReferrerURL : document.referrer || undefined,
            mx_Grade: Number(LSQPayload?.grade || resp?.grade?.gradeId) || undefined,
            mx_Exam: LSQPayload?.exam || resp?.exams?.[0]?.name?.replace(/[^a-z]/ig,'').toUpperCase() || undefined,
            mx_Primary_Target_Exam: LSQPayload?.exam || resp?.exams?.[0]?.name?.replace(/[^a-z]/ig,'').toUpperCase() || undefined,
            EmailAddress: LSQPayload?.email || undefined,
            mx_Business_Unit: 'Learn'
          }
          let firebasePayload = {
            "ActivityDateTime": moment().utc().format("YYYY-MM-DD HH:mm:ss"),
            "Fields": fields,
            "FirstName": resp?.firstName,
            "LastName": resp?.lastName,
            "environment": environment?.branch,
            "phone": resp?.phone,
            "Source": lsqSource || "IL Website",
            "productId": 1,
            "whatsappConsent": !this.isExitingUser ? this.whatsappConsent : undefined,
            "type": "Lead",
          }
          this.sendSQSMsg(firebaseConfig?.access_key, firebaseConfig?.secret_key, firebaseConfig,firebasePayload);
          let activityFields = {
            mx_Custom_1 : LSQPayload?.utm_url?.slice(0,190) || undefined,
            mx_Custom_2 : LSQPayload?.utm_source || undefined,
            mx_Custom_3 : LSQPayload?.utm_campaign || undefined,
            mx_Custom_4 : LSQPayload?.utm_medium || undefined,
            mx_Custom_5 : LSQPayload?.utm_content || undefined,
            mx_Custom_6 : LSQPayload?.utm_id || undefined,
            mx_Custom_7 : LSQPayload?.utm_term|| undefined,
            mx_Custom_8 : LSQPayload?.page || undefined,
            mx_Custom_11:  Number(LSQPayload?.grade || resp?.grade?.gradeId) || undefined,
            mx_Custom_12:  LSQPayload?.exam || resp?.exams?.[0]?.name?.replace(/[^a-z]/ig,'').toUpperCase() || undefined,
            mx_Custom_13: document.referrer || undefined,
            mx_Custom_14: window.location.pathname,
            mx_Custom_15: this.route.snapshot.queryParamMap.get('gclid') || this.route.snapshot.queryParamMap.get('fbclid') ? 'Paid' : 'Organic'
          }
          let activityPayload = {
            "ActivityDateTime": moment().utc().format("YYYY-MM-DD HH:mm:ss"),
            "ActivityEvent": 255,
            "Fields": activityFields,
            "FirstName": resp?.firstName,
            "LastName": resp?.lastName,
            "environment": environment?.branch,
            "phone": resp?.phone,
            "productId": 1,
            "type": "Activity",
          }
          setTimeout(()=>{
            this.sendSQSMsg(firebaseConfig?.access_key, firebaseConfig?.secret_key, firebaseConfig, activityPayload);
          },2000);
        }
      })
    })
  });
  }

  sendSQSMsgV2(payload){
    payload.ActivityDateTime = moment().utc().format("YYYY-MM-DD HH:mm:ss");
    if(payload?.type == 'Lead' && !payload.Fields.mx_City && !payload.Fields.mx_State){
      console.log('no city')
        payload.Fields.mx_City = this.regionalDetails?.city || undefined;
        payload.Fields.mx_State = this.regionalDetails?.region || undefined;
        payload.Fields.mx_Country = this.regionalDetails?.country_name || undefined;
        // payload.Fields.SourceReferrerURL = document.referrer || undefined;
    }
    if(environment?.isSqsShow){
      this.firebaseService.getSqsValue().then((firebaseConfig)=>{
        this.sendSQSMsg(firebaseConfig?.access_key, firebaseConfig?.secret_key, firebaseConfig,payload)
      })
    }

  }
  sendSQSMsg(mAccessKeyId,mSecretAccessKey,mQueueUrl,payload){
    AWS.config.update({
      accessKeyId: mAccessKeyId,
      secretAccessKey: mSecretAccessKey,
      region: 'ap-south-1', // Replace with your desired region      
   })
   const sqs = new AWS.SQS();
   const params = {
    MessageBody: JSON.stringify(payload), // The message content
    // MessageGroupId: '1',
    // MessageDeduplicationId: '1',
    QueueUrl: environment?.branch === 'production'? mQueueUrl?.queue_url: mQueueUrl?.testing_queue_url // Replace with your SQS queue URL
    };

    sqs.sendMessage(params, (err, data) => {
      if (err) { console.log('Error', err);
    } else {
      console.log('Message sent successfully', data.MessageId);
    } });
  }

  LSQApi(payload) {
    // const url = 'https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r890d28e9668bf5645c3a403a0f4ba5aa&secretKey=9334e4573b1d9cfba894d8a93776b73af1fe3007';
    const url = 'https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r068afce9a04b44cda9e5f1d39e13993f&secretKey=b6baaef22ef7f3269861306598fae53f02e053e9';
    const headers = {}
    return this.getByHeaders<any>(url, payload, headers);    
  }

  userOrder(userId, payload) {
    const url = environment.omsapi+'/api/userorders';
    const headers = { 
      "Authorization": "Bearer "+localStorage.getItem('token'),
      "X-Page": payload?.data?.page || "WEB_IL_SITE",
      "X-Platform": "web",
      "X-Product": "1",
     }
    return this.getByHeaders<any>(url, payload, headers);    
  }
  
  public setCookie(name: string, value: any, expireDays: number, path: string = '') {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath = path ? `; path=${path}` : '';
    // document.cookie = `${name}=${value}; ${expires}${cpath};  SameSite=Lax`;
    // document.cookie = `${name}=${value};Domain=${environment.routingDomain}; ${expires}${cpath};  SameSite=Lax`;
    document.cookie = `${name}=${value};Domain=${environment?.routingDomain};${expires};Path=/;SameSite=Lax;Secure`;
  } 
  public getCookie(name: string) {
    const ca: Array<string> = decodeURIComponent(document.cookie).split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;

    for (let i  = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) === 0) {
            return c.substring(cookieName.length, c.length);
        }
    }
    return '';  
}

  protected getByHeaders<T>(url: string, body: any, headers: any): Observable<T> {
    return this._http.post<any>(url, body, {headers: headers})
      .pipe(map(data => {
        // Array.isArray(data.body) ? data.body : Observable.throw(new Error(data.httpStatus));
        // data.payload = this.transformerService.parseJsonData(data.payload);
        return data;
      }));
  }
  whatsappUpdate(request,token){
    let headers= new HttpHeaders()
    .set('Authorization', 'Bearer '+token)
    .set('accesstoken', 'Bearer '+token)
    return this._http.post(this.appUrl+"/api/whatsapp/welcomemessage",request,{ 'headers': headers }).pipe(catchError(this.errorHandler));   
  }
  getExams(token){
    let headers= new HttpHeaders()
    .set('Authorization', 'Bearer '+token)
    .set('x-tenant', 'infinitylearn')
    return this._http.get(this.appUrl+"/api/exams",{ 'headers': headers }).pipe(catchError(this.errorHandler));
  }
  getFullExams(){
    let headers= new HttpHeaders()
    .set('Authorization', 'Bearer ')
    .set('x-tenant', 'infinitylearn')
    return this._http.get(this.appUrl+"/api/exams",{ 'headers': headers }).pipe(catchError(this.errorHandler));
  }
  updateUserProfile(token?,body?,userid?){
    let updatedToken = token ? token :localStorage.getItem('token');
    // let headers= new HttpHeaders()
    // .set('Authorization', 'Bearer '+updatedToken)
    // .set('accesstoken', 'Bearer '+updatedToken)
    const headers = {
      "product-id": product_id,
      "tenant-id": tenant_id,
      "platform":"web",
      "Authorization":`Bearer ${updatedToken}`
    }
    return this._http.put(`${this.gateWayUrl}api/v1/user/learn/update_profile`,body,{ 'headers': headers }).pipe(catchError(this.errorHandler));
  }
  webengage_whatsapp(data) {
    const url = 'https://api.webengage.com/v1/accounts/14507cc80/users';
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Authorization": "Bearer 7846e2ab-4f1b-4153-a856-e5232c3fa180",
      "Content-Type": "application/json",
     }
    return this.getByHeaders<any>(url, data, headers);
  }
  addScoreSchool(token,body,userid){
    let updatedToken = token ?token :localStorage.getItem('token')
    let headers= new HttpHeaders()
    .set('Authorization', 'Bearer '+updatedToken)
    .set('accesstoken', 'Bearer '+updatedToken)
    return this._http.post(this.appUrl+"/api/userAsssets/users/"+userid,body,{ 'headers': headers }).pipe(catchError(this.errorHandler));
  }

  getStates(){
    let token = localStorage.getItem('token');
    let headers= new HttpHeaders()
    .set('Authorization', 'Bearer '+token)
    .set('x-tenant', 'infinitylearn')
    return this._http.get(this.appUrl+"/api/Region",{ 'headers': headers }).pipe(catchError(this.errorHandler));
  }

  getDistricts(id){
    let token = localStorage.getItem('token');
    let headers= new HttpHeaders()
    .set('Authorization', 'Bearer '+token)
    .set('x-tenant', 'infinitylearn')
    return this._http.get(this.appUrl+`/api/${id}/districts`,{ 'headers': headers }).pipe(catchError(this.errorHandler));
  }

  getCitys(id){
    let token = localStorage.getItem('token');
    let headers= new HttpHeaders()
    .set('Authorization', 'Bearer '+token)
    .set('x-tenant', 'infinitylearn')
    return this._http.get(this.appUrl+`/api/${id}/citys`,{ 'headers': headers }).pipe(catchError(this.errorHandler));
  }

  getCenter(id){
    let token = localStorage.getItem('token');
    let headers= new HttpHeaders()
    .set('Authorization', 'Bearer '+token)
    .set('x-tenant', 'infinitylearn')
    return this._http.get(this.appUrl+`/api/${id}/center`,{ 'headers': headers }).pipe(catchError(this.errorHandler));
  }

  multiRegistrationMessage(body:any) {
    let token = localStorage.getItem('token');
    let headers= new HttpHeaders()
    .set('Authorization', 'Bearer '+token)
    .set('x-tenant', 'infinitylearn')
    const url = this.appUrl + `/sms/multipletestregistration`;
    return this._http.post<any>(url, body, { 'headers': headers }).pipe(catchError(this.errorHandler));
  }

  registerGateWay(payload){
    this.getGrades().subscribe((grades:any)=>{
      let headers= new HttpHeaders()
      .set('X-Page', 'WEB_IL_SITE ')
      .set('X-Product', '1')
      .set('product-id', '300')
      .set('tenant-id', '1')
      .set('platform', 'web')
      let body = {
        first_name: payload?.firstName,
        last_name: payload?.lastName,
        phone:  payload?.phone,
        whatsapp_consent: payload?.whatsappConsent,
        isd_code: payload?.isdCode,
        grade_id: grades?.find(g=>g.gradeId == payload?.gradeId)?.name?.replace(/[^0-9]/g, ''),  
        product_id: 300,
        tenant_id: 1,
        user_type: "student",
      }
      const url = `https://gatewayapi.devinfinitylearn.in/api/v1/user/learn/register`;
      this._http.post(url, body, {headers: headers}).pipe(catchError(this.errorHandler)).subscribe((response:any)=>{
        console.log(response,'gate way registration');
      });
    })
  }
}

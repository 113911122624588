import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
import { DatalayerService} from 'src/app/service/datalayer.service';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css'],
})
export class TestimonialComponent implements OnInit {

  constructor(
    private _services:ApiService,
    private _datalayer:DatalayerService
  ) { }

  testimonial:any = [];
  testimonial_active:any = [];
  
  ngOnInit(): void {

    this._services.testimonial_api().subscribe((result)=>{
      //console.log(result);
      this.testimonial_active = result.slice(0,1);
      this.testimonial = result.slice(1);
    })
  }
  we_join_for_free () {
    this._datalayer.webEngageEvent({
      'event': 'join_for_free_home' ,
      'page_url': window.location.href,
      }) 
    };
}

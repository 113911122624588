import { Injectable } from '@angular/core';
import firebase from "firebase/app";
import "firebase/remote-config";
import "firebase/remote-config";
import { websiteFirebaseConfig } from '../app.constant';
const app =  firebase.initializeApp(websiteFirebaseConfig);
@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor() { }

  async getSqsValue() {
    //const remoteConfig = getRemoteConfig();
    const remoteConfig = firebase.remoteConfig();
    remoteConfig.settings.minimumFetchIntervalMillis = 1000;
    return remoteConfig.fetchAndActivate()
      .then(() => {
        var key = 'website_lsq_config'
        //var val = getValue(remoteConfig, key);
        var val = remoteConfig.getValue(key);
        return JSON.parse(val.asString());
      }).catch((err) => {
        return JSON.parse("{}");
    });
  }

  async getRemoteConfig(config) {
    const remoteConfig = firebase.remoteConfig();
    remoteConfig.settings.minimumFetchIntervalMillis = 1000;
    return remoteConfig.fetchAndActivate()
      .then(() => {
        var key = config;
        var val = remoteConfig.getValue(key);
        return JSON.parse(val.asString());
      }).catch((err) => {
        return JSON.parse("{}");
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

@Component({
  selector: 'app-martyrsthankyou',
  templateUrl: './martyrsthankyou.component.html',
  styleUrls: ['./martyrsthankyou.component.css']
})
export class MartyrsthankyouComponent implements OnInit {
  fullName:any;
  course:any;
  redirection:any;
  time: number = 0;
  color:any;

  constructor(private router: Router) {     
    let fullData = this.router?.getCurrentNavigation()?.extras?.state?.data;
    console.log(fullData);
    this.fullName = fullData.fullName;
    this.course = fullData.course;
    this.redirection = fullData.redirection;
    this.color = fullData.color
    
  }

  ngOnInit(): void {
  }

}

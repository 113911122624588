import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ComingsoonComponent} from './components/comingsoon/comingsoon.component';
import {MartyrsthankyouComponent} from './components/martyrsthankyou/martyrsthankyou.component';

const routes: Routes = [
  { path: 'comingsoon', component: ComingsoonComponent },
  { path: 'thankyou', component: MartyrsthankyouComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule { }

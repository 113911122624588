import { Component, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LazyloadService } from './service/lazy-css-load';
import { MetatagsService } from './service/metatags.service';
import { newWebSiteRedirectionUrls } from './service/new_website_redirection_urls';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  pathName:any;
  pages = ["/cbse/6", "/cbse/7", "/cbse/8", "/cbse/9",
            "/cbse/10", "/cbse/11", "/cbse/12",'/cbse-fullcourse/10','/cbse-fullcourse/12'];
  newWebSiteUrl = environment.newWebsiteUrl;
  newUrl = newWebSiteRedirectionUrls[window.location.pathname];
  constructor(private loadcss: LazyloadService,
    private _services:MetatagsService,
    private metaService: Meta,
    private titleService: Title,
    private router: Router) { }
  
  ngOnInit(): void {
    if(this.newUrl){
      window.open(`${this.newWebSiteUrl}${this.newUrl}`,"_self");
    }
    // this.loadcss.loadStyle("/assets/css/bootstrap/bootstrap4-6.min.css");
    // this.loadcss.loadStyle("/assets/css/home.min.css");
    // this.loadcss.loadStyle("/assets/css/homeresponsive.min.css");
    // this.loadcss.loadStyle("/assets/css/loginregister.css");
    // this.loadcss.loadStyle("/assets/css/teams.css");
    // this.loadcss.loadStyle("/assets/css/bootstrap/font-awesome/font-awesome.min.css");
    // this.loadcss.loadStyle("/assets/css/owl/owl-carousel1.min.css");
    // this.loadcss.loadStyle("/assets/css/owl/owl-carousel2.min.css");
    // this.loadcss.loadStyle("/assets/css/owl/owl-default.min.css");
    // this.loadcss.loadStyle("/assets/chartboard.min.css");
    this._services.metatag(window.location.pathname).subscribe(res=>{
      console.log(res,'meta data home');
      this.titleService.setTitle(res?.[0]?.meta_name);
      this.metaService.updateTag({ name: 'title', content: res?.[0]?.meta_name }, "name='title'");
      this.metaService.updateTag({ name: 'keywords', content: res?.[0]?.meta_keyword}, "name='keywords'");
      this.metaService.updateTag({ name: 'description', content: res?.[0]?.meta_description }, "name='description'");
      this.metaService.addTags([
        { property: 'og:title', content: res?.[0]?.meta_name },
        { property: 'og:keywords', content: res?.[0]?.meta_keyword},
        { property: 'og:description', content: res?.[0]?.meta_description },
        { property: 'og:url', content: window.location.href }
      ]);
    })
  }

  // ngAfterViewInit() {
  //   this.loadcss.loadStyle("/assets/css/bootstrap/bootstrap4-6.min.css");
  //   this.loadcss.loadStyle("/assets/css/home.min.css");
  //   this.loadcss.loadStyle("/assets/css/homeresponsive.min.css");
  //   this.loadcss.loadStyle("/assets/css/loginregister.css");
  //   this.loadcss.loadStyle("/assets/css/teams.css");
  //   this.loadcss.loadStyle("/assets/css/bootstrap/font-awesome/font-awesome.min.css");
  //   this.loadcss.loadStyle("/assets/css/owl/owl-carousel1.min.css");
  //   this.loadcss.loadStyle("/assets/css/owl/owl-carousel2.min.css");
  //   this.loadcss.loadStyle("/assets/css/owl/owl-default.min.css");
  //   this.loadcss.loadStyle("/assets/chartboard.min.css");
  // }
}

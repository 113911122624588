export const lsqNames = [
    {
     "Page": "/jee-11",
     "Source": "IL Website",
     "PageName": "JEE-11"
    },
    {
     "Page": "/jee-12",
     "Source": "IL Website",
     "PageName": "JEE-12"
    },
    {
     "Page": "/jee-13",
     "Source": "IL Website",
     "PageName": "JEE-13"
    },
    {
     "Page": "/neet-11",
     "Source": "IL Website",
     "PageName": "NEET-11"
    },
    {
     "Page": "/neet-12",
     "Source": "IL Website",
     "PageName": "NEET-12"
    },
    {
     "Page": "/neet-13",
     "Source": "IL Website",
     "PageName": "NEET-13"
    },
    {
     "Page": "/foundation-jee-9",
     "Source": "IL Website",
     "PageName": "Foundation-JEE-9"
    },
    {
     "Page": "/foundation-jee-10",
     "Source": "IL Website",
     "PageName": "Foundation-JEE-10"
    },
    {
     "Page": "/foundation-neet-9",
     "Source": "IL Website",
     "PageName": "Foundation-NEET-9"
    },
    {
     "Page": "/foundation-neet-10",
     "Source": "IL Website",
     "PageName": "Foundation-NEET-10"
    },
    {
     "Page": "/neet-rap",
     "Source": "Marketing Pages",
     "PageName": "NEET-RAP"
    },
    {
     "Page": "/jee-advanced",
     "Source": "IL Website",
     "PageName": "JEE-Advanced-2023"
    },
    {
     "Page": "/jee-advanced-test-series",
     "Source": "IL Website",
     "PageName": "JEE-Advanced-2023"
    },
    {
     "Page": "/courses-jee-11",
     "Source": "Self Checkout",
     "PageName": "Edge-jee-11"
    },
    {
     "Page": "/courses-jee-12",
     "Source": "Self Checkout",
     "PageName": "Edge-jee-12"
    },
    {
     "Page": "/courses-neet-11",
     "Source": "Self Checkout",
     "PageName": "Edge-neet-11"
    },
    {
     "Page": "/courses-neet-12",
     "Source": "Self Checkout",
     "PageName": "Edge-neet-12"
    },
    {
     "Page": "/raank",
     "Source": "IL Website",
     "PageName": "RAANK-TEST"
    },
    {
     "Page": "/patna",
     "Source": "Marketing Pages",
     "PageName": "Patna-Knowledgehub"
    },
    {
     "Page": "/masterclasses",
     "Source": "IL Website",
     "PageName": "FREEMASTERCLASSES"
    },
    {
     "Page": "/book-a-free-class-today",
     "Source": "Marketing Pages",
     "PageName": "Book-A-Free-Class"
    },
    {
     "Page": "/online-mock-tests",
     "Source": "Marketing Pages",
     "PageName": "Online-Mock-Tests"
    },
    {
     "Page": "/neet-longterm-11",
     "Source": "Marketing Pages",
     "PageName": "NEET-11-V2"
    },
    {
     "Page": "/jee-longterm-11",
     "Source": "Marketing Pages",
     "PageName": "JEE-11-V2"
    },
    {
     "Page": "/foundation-course",
     "Source": "Marketing Pages",
     "PageName": "JEE-NEET-Foundation-Course"
    },
    {
     "Page": "/aits",
     "Source": "Marketing Pages",
     "PageName": "AITS"
    },
    {
     "Page": "/jee-crashcourse",
     "Source": "Marketing Pages",
     "PageName": "JEE-Crash-Course"
    },
    {
     "Page": "/neet-crashcourse",
     "Source": "Marketing Pages",
     "PageName": "NEET-Crash-Course"
    },
    {
     "Page": "/jee-advanced-crash-course",
     "Source": "Marketing Pages",
     "PageName": "JEE-Advanced-Crash-Course"
    },
    {
     "Page": "/neet-longterm",
     "Source": "Marketing Pages",
     "PageName": "NEET-Longterm"
    },
    {
     "Page": "/jee-longterm",
     "Source": "Marketing Pages",
     "PageName": "JEE-Longterm"
    },
    {
     "Page": "/jee-longterm/13",
     "Source": "Marketing Pages",
     "PageName": "JEE-Longterm-13"
    },
    {
     "Page": "/neet-longterm/13",
     "Source": "Marketing Pages",
     "PageName": "NEET-Longterm-13"
    },
    {
     "Page": "/neet-longterm/11",
     "Source": "Marketing Pages",
     "PageName": "NEET-Longterm-11"
    },
    {
     "Page": "/jee-longterm/11",
     "Source": "Marketing Pages",
     "PageName": "JEE-Longterm-11"
    },
    {
     "Page": "/foundation-course-class-8",
     "Source": "Marketing Pages",
     "PageName": "Foundation-8"
    },
    {
     "Page": "/foundation-course-class-9",
     "Source": "Marketing Pages",
     "PageName": "Foundation-9"
    },
    {
     "Page": "/foundation-course-class-10",
     "Source": "Marketing Pages",
     "PageName": "Foundation-10"
    },
    {
     "Page": "/foundation-course-class-10-v2",
     "Source": "Marketing Pages",
     "PageName": "Foundation-10-V2"
    },
    {
     "Page": "/rap",
     "Source": "Marketing Pages",
     "PageName": "NEET RAP"
    },
    {
     "Page": "/rrr",
     "Source": "Marketing Pages",
     "PageName": "JEE-RRR"
    },
    {
     "Page": "/selflearn",
     "Source": "Marketing Pages",
     "PageName": "Marketing Pages"
    },
    {
     "Page": "/rank-predictor-neet",
     "Source": "Marketing Pages",
     "PageName": "Rank-Predictor-NEET"
    },
    {
     "Page": "/rank-predictor-jee",
     "Source": "Marketing Pages",
     "PageName": "Rank-Predictor-JEE"
    },
    {
     "Page": "/trial-packages",
     "Source": "Marketing Pages",
     "PageName": "Trial-Package"
    },
    {
     "Page": "/referral",
     "Source": "IL Website",
     "PageName": "referral"
    },
    {
     "Page": "/contest-rs",
     "Source": "Marketing Pages",
     "PageName": "rohit-sharma-campaign"
    },
    {
        "Page": "/book-detail",
        "Source": "Marketing Pages",
        "PageName": "Books-Packages"
    },
    {
     "Page": "/courses",
     "Source": "Self Checkout",
     "PageName": "courses"
    },
   ]
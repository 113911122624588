import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MetatagsService} from 'src/app/service/metatags.service';
import { months, unifiedWeb } from 'src/app/service/app.constant';
import { environment } from 'src/environments/environment';
import {surgedata} from 'src/app/service/surge-data';
import { DatalayerService } from 'src/app/service/datalayer.service';
import { HttpClient } from '@angular/common/http';
import { UserRegistrationService } from 'src/app/service/user-registration.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  studentportal = environment.studentportal;
  utm:any = [];
  constructor(
    private _metaservices:MetatagsService,
    public route: ActivatedRoute,
    private router: Router,
    private _datalayer: DatalayerService,
    private http: HttpClient,
    private userRegistrationService: UserRegistrationService) {}
  public unifiedWeb  = unifiedWeb;
  surgeData = surgedata;

  Grades = Grades;
  ngOnInit(): void {
    this._metaservices.createLinkForCanonicalURL();
    this.getIPAddress();
    console.log(this.router.url);
    if(this.router.url.split('?')[1] != undefined) {
      this.utm = '?'+this.router.url.split('?')[1]+'&page='+this.router.url.split('?')[0].slice(1);
    } else if(this.router.url != '/') {
      this.utm = '?page='+this.router.url.split('?')[0].slice(1);
    } else if(this.router.url == '/'){
      this.utm = '?page=signup'
    }
    if(sessionStorage.getItem("isFromBajajFinservAppInApp") === "true" && !this.utm?.includes("utm_source=BajajFinserv")) {
      this.utm += "&utm_source=BajajFinserv&utm_medium=AppInApp"
    }
  }

  naviagteToGrade(grade: any) {
    window.open('/cbse/'+grade+'/full-course','_self');
  }
  
  // naviagteToCrash(grade: any) {
  //   window.open('/cbse/'+grade+'/crash-course','_self');
  // }

  naviagteToCrash(grade: any) {
    window.open('/cbse/'+grade,'_self');
  }

  naviagteFuondation(course){
    window.open('foundation/'+course,'_self');
  }

  redirection(route){
    window.open(route,'_self');
  }

  teststage() {
    console.log(environment.name)
    console.log(environment.studentportal)
  }
  navigateTosignupPage(redirectTo){
    if(redirectTo == 'signin' && this.utm =="?page=signup"){
      window.location.href =`${this.studentportal}signin?page=signin`;
    }else{
      window.location.href =this.studentportal+'signin'+this.utm;
    }
  }

  callNow(){
    this._datalayer.webEngageEvent({
      'event': 'call_icon_clicked',
      'page_url': window.location.href,
      'button_name': 'Call_Now',
      'button_section': "Header",
      'exp_version': "A",
      'lead_category': this.route.snapshot.queryParamMap.get('gclid') ? "Marketing" : "Organic",
      'utm_source': this.route.snapshot.queryParamMap.get('utm_source'),
      'month': months[new Date().getMonth()]
    })
  }

  getIPAddress(){
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      console.log(res)
      this.http.get(`https://ipapi.co/${res.ip}/json`).subscribe((regionalDetails:any)=>{
       console.log('regionalDetails header:',regionalDetails);
      this.userRegistrationService.regionalDetails = regionalDetails;
      },(error)=>{
        console.log(error);
      });
    }, (error)=>{
      console.log(error);
    });
  }
  
}

export const Grades = [
  {grade: '6'},
  {grade: '7'},
  {grade: '8'},
  {grade: '9'},
  {grade: '10'},
  {grade: '11'},
  {grade: '12'},
]

import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import {HeaderComponent} from './components/header/header.component';
import {HeaderMobComponent} from './components/header-mob/header-mob.component';
import {HeaderSignupComponent} from './components/header-signup/header-signup.component';
import {FooterComponent} from './components/footer/footer.component';
import {AlertComponent} from './components/alert/alert.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {NgOtpInputModule} from 'ng-otp-input';
import {TestimonialComponent} from './components/testimonial/testimonial.component';
import {TrynewComponent} from './components/trynew/trynew.component';
import {VideocomponetComponent} from './components/videocomponet/videocomponet.component';
import {FacultyComponent} from './components/faculty/faculty.component';
import {FaqComponent} from './components/faq/faq.component';
import {JeeOurresultsComponent} from './components/jee-ourresults/jee-ourresults.component';
import {LiveclassComponent} from './components/liveclass/liveclass.component';
import {MasterclassFacultyComponent} from './components/masterclass-faculty/masterclass-faculty.component';
import {NeetOurresultsComponent} from './components/neet-ourresults/neet-ourresults.component';
import {NewThankyouComponent} from './components/new-thankyou/new-thankyou.component';
import {OurResultsComponent} from './components/our-results/our-results.component';
import {TopheadingComponent} from './components/topheading/topheading.component';
import {ComingsoonComponent} from './components/comingsoon/comingsoon.component';
import {EssayComponent} from './components/qna/essay/essay.component';
import {Question1Component} from './components/qna/question1/question1.component';
import {MartyrsComponent} from './components/martyrs/martyrs.component';
import {MartyrsthankyouComponent} from './components/martyrsthankyou/martyrsthankyou.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { JeeLiveclassComponent } from '../product/components/jee-liveclass/jee-liveclass.component';
import { NeetLiveclassComponent } from '../product/components/neet-liveclass/neet-liveclass.component';
import { WhatsappiconComponent } from './components/whatsappicon/whatsappicon.component';
import { NewFooterComponent } from './components/new-footer/new-footer.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { GradeConversionPipe } from './pipes/grade-conversion.pipe';


@NgModule({
  declarations: [
    HeaderComponent,
    HeaderMobComponent,
    HeaderSignupComponent,
    FooterComponent,
    AlertComponent,
    TestimonialComponent,
    VideocomponetComponent,
    TrynewComponent,
    FacultyComponent,
    LiveclassComponent,
    MasterclassFacultyComponent,
    OurResultsComponent,
    JeeOurresultsComponent,
    NeetOurresultsComponent,
    FaqComponent,
    NewThankyouComponent,
    TopheadingComponent,
    ComingsoonComponent,
    Question1Component,
    EssayComponent,
    MartyrsComponent,
    MartyrsthankyouComponent,
    NeetLiveclassComponent,
    JeeLiveclassComponent,
    WhatsappiconComponent,
    NewFooterComponent,
    NotfoundComponent,
    GradeConversionPipe,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgOtpInputModule,
    LazyLoadImageModule
  ],
  exports: [
    HeaderComponent,
    HeaderMobComponent,
    HeaderSignupComponent,
    FooterComponent,
    NewFooterComponent,
    AlertComponent,
    FacultyComponent,
    JeeOurresultsComponent,
    NeetOurresultsComponent,
    FaqComponent,
    VideocomponetComponent,
    LiveclassComponent,
    MartyrsComponent,
    TestimonialComponent,
    NeetLiveclassComponent,
    JeeLiveclassComponent,
    WhatsappiconComponent,
    GradeConversionPipe,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
})
export class SharedModule { }

<div class="container">
  <div class="row">
    <div class="col-lg-6 col-md-6 col-xs-12">
      <div class="il-footerlogo">
        <figure class="mb-0">
          <img *ngIf="this.router.url.split('?')[0]!='/schools'" src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/ilwhite.png" alt=""
            class="footerlogo" loading="lazy">
          <img *ngIf="this.router.url.split('?')[0]=='/schools'" src="/assets/images/ilschools-footer.png" alt=""
            class="footerlogo" loading="lazy">
        </figure>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-xs-12 quickflex">
      <div class="row align-items-center">
        <div class="col-lg-12 col-md-12 col-xs-12">
          <h4 class="quicklink">Quick Links</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
          <div class="mobsocial">
            <a (click)="we_socialmedia('youtube')" href="https://www.youtube.com/c/InfinityLearnEdu" target="_blank">
              <img src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/youtube.png" alt="youtube" class="socialicons" loading="lazy"/>
            </a>
            <a (click)="we_socialmedia('instagram')" href=" https://www.instagram.com/infinitylearn_by_srichaitanya/"
              target="_blank">
              <img src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/instagram.png" alt="instagram" class="socialicons" loading="lazy" />
            </a>
            <a (click)="we_socialmedia('facebook')" href="https://www.facebook.com/InfinityLearn.SriChaitanya/"
              target="_blank">
              <img src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/facebook.png" alt="facebook" class="socialicons" loading="lazy" />
            </a>
            <a (click)="we_socialmedia('linkedin')"
              href=" https://www.linkedin.com/company/infinity-learn-by-sri-chaitanya/" target="_blank">
              <img src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/linkedin.png" alt="linkedin" class="socialicons" loading="lazy" />
            </a>
            <a (click)="we_socialmedia('twitter')" href="https://twitter.com/InfinityLearn_" target="_blank"><img
                src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/twitter.png" alt="twitter" class="socialicons"  loading="lazy"/>
            </a>
          </div>
        </div>
      </div>


    </div>

  </div>
  <div class="row address-flex">
    <div class="col-lg-6 col-md-6 col-xs-12">
      <div>
        <div class="footer-flex mb-15">
          <div class="mr-25">
            <i class="fa fa-map-marker" aria-hidden="true"></i>
          </div>
          <div>
            <p class="faddress">6th Floor, NCC Building, Durgamma Cheruvu <br> Road, Vittal Rao Nagar, HITEC City,
              <br> Hyderabad, Telangana 500081.
            </p>
          </div>
        </div>
        <div class="footer-flex mb-15">
          <div class="mr-25">
            <i class="fa fa-envelope" aria-hidden="true"></i>
          </div>
          <div>
            <a href="mailto:support@infinitylearn.com">
              <p class="faddress">support@infinitylearn.com</p>
            </a>
          </div>
        </div>
        <div class="footer-flex mb-15">
          <div class="mr-25">
            <i class="fa fa-phone" aria-hidden="true"></i>
          </div>
          <div>
            <!-- <p *ngIf="!isJeeCrashcourse && !isPatna" class="faddress"><a href="tel:18004194247" class="fsupport">1800 419 4247</a></p> -->
            <p *ngIf="!isJeeCrashcourse && !isPatna" class="faddress"><a href="tel:7996668865" class="fsupport">For Admission Enquiries <br> 799 666 8865</a></p>
            <p *ngIf="isJeeCrashcourse" class="faddress"><a  class="fsupport">9705328540 & 9908806136</a></p>
            <p *ngIf="isJeeCrashcourse" class="faddress"><a  class="fsupport">7993704858 & 8890825104</a></p>
            <p *ngIf="isPatna" class="faddress"><a href="tel:9100499885" class="fsupport">9100499885</a></p>
          </div>
        </div>
        <div class="footer-flex mb-15">
          <div class="mr-25">
            <i class="fa fa-phone" aria-hidden="true"></i>
          </div>
          <div>
            <p class="faddress">Customer Care Number <br><a href="tel:18004194247" class="fsupport">18004194247</a></p>
          </div>
        </div>
        <div class="footer-flex mb-15">
          <div class="mr-25">
            <i class="fa fa-building-o" aria-hidden="true"></i>
          </div>
          <div>
            <p class="faddress">Sri Chaitanya College
              Admission Enquiries<br>040-66060606</p>
          </div>
        </div>
        <div class="footer-flex mb-15">
          <div class="mr-25">
            <i class="fa fa-building-o" aria-hidden="true"></i>
          </div>
          <div>
            <p class="faddress">Sri Chaitanya School Admission Enquiries<br>040-71045045 &
              040-44600600 Ext 401, 402 & 425</p>
          </div>
        </div>
      </div>
      <div class="social">
        <ng-container *ngIf="isFromBajajFinservAppInApp">
        <a><img src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/youtube.png" alt="youtube" class="socialicons" loading="lazy"></a>
        <a><img src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/instagram.png" alt="instagram" class="socialicons" loading="lazy"></a>
        <a><img src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/facebook.png" alt="facebook" class="socialicons" loading="lazy"></a>
        <a><img src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/linkedin.png" alt="linkedin" class="socialicons" loading="lazy"></a>
        <a><img src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/twitter.png" alt="twitter" class="socialicons" loading="lazy"></a>
        </ng-container>
        <ng-container *ngIf="!isFromBajajFinservAppInApp">
          <a (click)="we_socialmedia('youtube')" href="https://www.youtube.com/c/InfinityLearnEdu" target="_blank"><img
            src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/youtube.png" alt="youtube" class="socialicons" loading="lazy"></a>
        <a (click)="we_socialmedia('instagram')" href="https://www.instagram.com/infinitylearn_by_srichaitanya/"
          target="_blank"><img src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/instagram.png" alt="instagram" class="socialicons" loading="lazy"></a>
        <a (click)="we_socialmedia('facebook')" href="https://www.facebook.com/InfinityLearn.SriChaitanya/"
          target="_blank"><img src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/facebook.png" alt="facebook" class="socialicons" loading="lazy"></a>
        <a (click)="we_socialmedia('linkedin')" href="https://www.linkedin.com/company/infinity-learn-by-sri-chaitanya/"
          target="_blank"><img src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/linkedin.png" alt="linkedin" class="socialicons" loading="lazy"></a>
        <a (click)="we_socialmedia('twitter')" href="https://twitter.com/InfinityLearn_" target="_blank"><img
            src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/twitter.png" alt="twitter" class="socialicons" loading="lazy"></a>
        </ng-container>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-xs-12">

      <div class="row">
        <div class="col-md-4 col-4">
          <div>
            <ul class="widgetarea">
              <li><a href="/about">About Us</a></li>
              <li><a href="https://infinitylearn.skillate.com/">Careers</a></li>
              <li><a href="/leadership">Teams</a></li>
              <li><a href="/life-at-infinitylearn">Life at Infinity Learn</a></li>
              <li><a href="https://infinitylearn.com/surge/blog/">Blogs</a></li>
              <li><a href="/press">Press</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-5 col-5">
          <div>
            <ul class="widgetarea">
              <li><a href="https://infinitylearn.com/surge/" target="_blank">Free Material</a></li>
              <li><a href="/teacher">Become a Teacher</a></li>
              <li><a href="/ambassador">Become an Ambassador</a></li>
              <li><a href="/jee-13">JEE Repeater</a></li>
              <li><a href="/neet-13">NEET Repeater</a></li>
              <li><a href="/results">Our Results</a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-3">
          <div>
            <ul class="widgetarea">
              <li><a href="https://srichaitanyaalumni.com">Alumni</a></li>
              <li><a href="/terms">Terms & Conditions</a></li>
              <li><a href="/privacy">Privacy Policy</a></li>
              <li><a href="/refund-policy">Refund Policy</a></li>
              <li><a href="/grievances">Grievance Redressal</a></li>
            </ul>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4 col-lg-4 col-4">
          <h6 class="fplayapps">InfinityLearn App</h6>
          <figure>
            <a *ngIf="isFromBajajFinservAppInApp"><img
                src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/playstore-icon.png" alt="" class="ftrplaystore" loading="lazy"></a>
            <a *ngIf="!isFromBajajFinservAppInApp" href="https://play.google.com/store/apps/details?id=com.infinitylearn.learn" target="_blank"><img
                  src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/playstore-icon.png" alt="" class="ftrplaystore" loading="lazy"></a>
          </figure>
        </div>
        <!-- <div class="col-md-4 col-lg-4 col-4">
          <h6 class="fplayapps">Doubts App</h6>
          <figure>
            <a *ngIf="isFromBajajFinservAppInApp"><img
                src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/playstore-icon.png" alt="" class="ftrplaystore" loading="lazy"></a>
                <a *ngIf="!isFromBajajFinservAppInApp" href="https://play.google.com/store/apps/details?id=com.infinitylearn.doubt" target="_blank"><img
                  src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Homepage/playstore-icon.png" alt="" class="ftrplaystore" loading="lazy"></a>
          </figure>
        </div> -->
      </div>

    </div>
  </div>
  <div class="row stud_border">
    <div class="col-md-12 col-lg-12 col-12">
      <h4 class="study_mat_title">Youtube Channels <span class="channelstroke"></span></h4>
    </div>
  </div>
  <div class="row channel_border">
      <div class="col-md-12">
          <div class="channels_flex">
              <div>
                  <h4 class="ychannels"><a href="https://www.youtube.com/@InfinityLearnEdu" target="_blank" class="y_link">Infinity Learn</a></h4>
              </div>
              <div>
                  <h4 class="ychannels"><a href="https://www.youtube.com/channel/UCiTjCIT_9EXV1Wp1cY0zaUA" target="_blank" class="y_link">Infinity Learn NEET</a></h4>
              </div>
              <div>
                  <h4 class="ychannels"><a href="https://www.youtube.com/channel/UCSZVTk6wAtmorpBjP_uEjpw" target="_blank" class="y_link">Infinity Learn JEE</a></h4>
              </div>
              <div>
                  <h4 class="ychannels"><a href="https://www.youtube.com/channel/UCXbYgdeEMH934DrhypI1MmA" target="_blank" class="y_link">Infinity Learn Class 9&10</a></h4>
              </div>
          </div>
      </div>
  </div>
  <div class="row stud_border">
    <div class="col-md-12 col-lg-12 col-12">
      <h4 class="study_mat_title">Telegram Channels <span class="channelstroke"></span></h4>
    </div>
  </div>
  <div class="row channel_border">
      <div class="col-md-12">
          <div class="channels_flex">
              <div>
                  <h4 class="ychannels"><a href="https://t.me/InfinityLearnEdu" target="_blank" class="y_link">Infinity Learn</a></h4>
              </div>
              <div>
                  <h4 class="ychannels"><a href="https://t.me/Infinity_Learn_NEET" target="_blank" class="y_link">Infinity Learn NEET</a></h4>
              </div>
              <div>
                  <h4 class="ychannels"><a href="https://t.me/InfinityLearnJEE" target="_blank" class="y_link">Infinity Learn JEE</a></h4>
              </div>
              <div>
                  <h4 class="ychannels"><a href="https://t.me/InfinityLearn9_10" target="_blank" class="y_link">Infinity Learn Class 9&10</a></h4>
              </div>
          </div>
      </div>
  </div>
  <div class="row stud_border">
    <div class="col-md-12 col-lg-12 col-12">
      <h4 class="study_mat_title">Study Material <span class="strokeyellow"></span></h4>
    </div>
  </div>
  <div class="row top-border">
    <div class="col-md-4 col-lg-4">
      <div class="footer-widget">
        <ul>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/" target="_blank"><strong>NCERT
                Solutions</strong></a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-12/" target="_blank">NCERT
              Solutions For
              Class 12</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-12/maths/"
              target="_blank">NCERT Solutions
              For Class 12 Maths</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-12/biology/"
              target="_blank">NCERT
              Solutions For Class 12 Biology</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-12/physics/"
              target="_blank">NCERT
              Solutions For Class 12 Physics</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-12/chemistry/"
              target="_blank">NCERT
              Solutions For Class 12 Chemistry</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-11/" target="_blank">NCERT
              Solutions For
              Class 11</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-11/maths/"
              target="_blank">NCERT Solutions
              For Class 11 Maths</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-11/biology/"
              target="_blank">NCERT
              Solutions For Class 11 Biology</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-11/physics/"
              target="_blank">NCERT
              Solutions For Class 11 Physics</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-11/chemistry/"
              target="_blank">NCERT
              Solutions For Class 11 Chemistry</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-10/" target="_blank">NCERT
              Solutions For
              Class 10</a></li>


        </ul>
      </div>
    </div>
    <div class="col-md-4 col-lg-4">
      <div class="footer-widget">
        <ul>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-10/maths/"
              target="_blank">NCERT Solutions
              For Class 10 Maths</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-10/english/"
              target="_blank">NCERT
              Solutions For Class 10 English</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-10/science/"
              target="_blank">NCERT
              Solutions For Class 10 Science</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-10/social-science/"
              target="_blank">NCERT
              Solutions For Class 10 Social Science</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-9/" target="_blank">NCERT
              Solutions For
              Class 9</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-9/maths/"
              target="_blank">NCERT Solutions
              For Class 9 Maths</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-9/english/"
              target="_blank">NCERT Solutions
              For Class 9 English</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-9/science/"
              target="_blank">NCERT Solutions
              For Class 9 Science</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-9/social-science/"
              target="_blank">NCERT
              Solutions For Class 9 Social Science</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-8/" target="_blank">NCERT
              Solutions For
              Class 8</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-8/maths/"
              target="_blank">NCERT Solutions
              For Class 8 Maths</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-8/english/"
              target="_blank">NCERT Solutions
              For Class 8 English</a></li>

        </ul>
      </div>
    </div>
    <div class="col-md-4 col-lg-4">
      <div class="footer-widget">
        <ul>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-8/science/"
              target="_blank">NCERT Solutions
              For Class 8 Science</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-8/social-science/"
              target="_blank">NCERT
              Solutions For Class 8 Social Science</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-7/" target="_blank">NCERT
              Solutions For
              Class 7</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-7/maths/"
              target="_blank">NCERT Solutions
              For Class 7 Maths</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-7/english/"
              target="_blank">NCERT Solutions
              For Class 7 English</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-7/science/"
              target="_blank">NCERT Solutions
              For Class 7 Science</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-7/social-science/"
              target="_blank">NCERT
              Solutions For Class 7 Social Science</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-6/" target="_blank">NCERT
              Solutions For
              Class 6</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-6/maths/"
              target="_blank">NCERT Solutions
              For Class 6 Maths</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-6/english/"
              target="_blank">NCERT Solutions
              For Class 6 English</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-6/science/"
              target="_blank">NCERT Solutions
              For Class 6 Science</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-solutions/class-6/social-science/"
              target="_blank">NCERT
              Solutions For Class 6 Social Science</a></li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row exem_border">
    <div class="col-md-4 col-lg-4">
      <div class="footer-widget">
        <ul>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/"
              target="_blank"><strong>NCERT Exemplar
                Solutions</strong></a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-12/"
              target="_blank">NCERT
              Exemplar Solutions For Class 12</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-12/maths/"
              target="_blank">NCERT
              Exemplar Solutions For Class 12 Maths</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-12/biology/"
              target="_blank">NCERT
              Exemplar Solutions For Class 12 Biology</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-12/physics/"
              target="_blank">NCERT
              Exemplar Solutions For Class 12 Physics</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-12/chemistry/"
              target="_blank">NCERT
              Exemplar Solutions For Class 12 Chemistry</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-11/"
              target="_blank">NCERT
              Exemplar Solutions For Class 11</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-11/maths/"
              target="_blank">NCERT
              Exemplar Solutions For Class 11 Maths</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-11/biology/"
              target="_blank">NCERT
              Exemplar Solutions For Class 11 Biology</a></li>

        </ul>
      </div>
    </div>
    <div class="col-md-4 col-lg-4">
      <div class="footer-widget">
        <ul>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-11/physics/"
              target="_blank">NCERT
              Exemplar Solutions For Class 11 Physics</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-11/chemistry/"
              target="_blank">NCERT
              Exemplar Solutions For Class 11 Chemistry</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-10/"
              target="_blank">NCERT
              Exemplar Solutions For Class 10</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-10/maths/"
              target="_blank">NCERT
              Exemplar Solutions For Class 10 Maths</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-10/science/"
              target="_blank">NCERT
              Exemplar Solutions For Class 10 Science</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-9/"
              target="_blank">NCERT Exemplar
              Solutions For Class 9</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-9/maths/"
              target="_blank">NCERT
              Exemplar Solutions For Class 9 Maths</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-9/science/"
              target="_blank">NCERT
              Exemplar Solutions For Class 9 Science</a></li>
        </ul>
      </div>
    </div>
    <div class="col-md-4 col-lg-4">
      <div class="footer-widget">
        <ul>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-8/"
              target="_blank">NCERT Exemplar
              Solutions For Class 8</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-8/maths/"
              target="_blank">NCERT
              Exemplar Solutions For Class 8 Maths</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-8/science/"
              target="_blank">NCERT
              Exemplar Solutions For Class 8 Science</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-7/"
              target="_blank">NCERT Exemplar
              Solutions For Class 7</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-7/maths/"
              target="_blank">NCERT
              Exemplar Solutions For Class 7 Maths</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-7/science/"
              target="_blank">NCERT
              Exemplar Solutions For Class 7 Science</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-6/"
              target="_blank">NCERT Exemplar
              Solutions For Class 6</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-6/maths/"
              target="_blank">NCERT
              Exemplar Solutions For Class 6 Maths</a></li>
          <li><a href="https://infinitylearn.com/surge/study-materials/ncert-exemplar-solutions/class-6/science/"
              target="_blank">NCERT
              Exemplar Solutions For Class 6 Science</a></li>
        </ul>
      </div>
    </div>
  </div>
  <!-- <div class="row stud_border">
    <div class="col-md-12 col-lg-12 col-12">
      <h4 class="study_mat_title">Edge Courses <span class="edgestroke"></span></h4>
    </div>
  </div>
  <div class="row channel_border">
      <div class="col-md-12">
          <div class="channels_flex">
              <div>
                  <h4 class="ychannels"><a href="http://infinitylearn.com/courses-jee-11" target="_blank" class="y_link">JEE 11</a></h4>
              </div>
              <div>
                  <h4 class="ychannels"><a href="http://infinitylearn.com/courses-neet-11" target="_blank" class="y_link">NEET 11</a></h4>
              </div>
              <div>
                  <h4 class="ychannels"><a href="http://infinitylearn.com/courses-jee-12" target="_blank" class="y_link">JEE 12</a></h4>
              </div>
              <div>
                  <h4 class="ychannels"><a href="http://infinitylearn.com/courses-neet-12" target="_blank" class="y_link">NEET 12</a></h4>
              </div>
          </div>
      </div>
  </div> -->
  <div class="row channel_border">
    <div class="col-lg-12 col-md-12 col-xs-12 text-center">
      <p class="copyright">© 2023, InfinityLearn. All Rights Reserved.</p>
    </div>
  </div>
</div>
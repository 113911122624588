import { Component, OnInit } from '@angular/core';
import { unifiedWeb } from 'src/app/service/app.constant';
import { MetatagsService } from 'src/app/service/metatags.service';
import { Router, ActivatedRoute } from '@angular/router';
import {DatalayerService} from 'src/app/service/datalayer.service';
import { ViewEncapsulation } from '@angular/core'

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AlertComponent implements OnInit {
public unifiedWeb  = unifiedWeb
alertmsg: any = [];
  constructor(
    private MetatagsService: MetatagsService,
    private router: Router,
    private _datalayer:DatalayerService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if(window.location.pathname == "/") {
      this.MetatagsService
      .alertmsg('/home')
      .subscribe((res) => {
        let alertmsg = res[0]; 
        //console.log(this.alertmsg)
        this.alertmsg = alertmsg; });
    } else {
        this.MetatagsService
        .alertmsg(window.location.pathname)
        .subscribe((res) => {
          let alertmsg = res[0]; 
          if(alertmsg) {
            this.alertmsg = alertmsg;
          } else {
            this.MetatagsService
            .alertmsg("/default")
            .subscribe((res) => {
              let alertmsg = res[0]; 
                this.alertmsg = alertmsg;
              });
          }
        });
    }

  }

  we_appDownload(){
    this._datalayer.webEngageEvent({
      'event': 'Download_Learn_App' ,
      'page_url': window.location.href,
      })
  }
  

}

<ul class="navbar-nav mr-auto w-100 justify-content-center clearfix align-items-center">
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false" style="cursor: pointer">
      STUDY MATERIAL
    </a>
    <ul class="dropdown-menu webmenuleft" aria-labelledby="navbarDropdownMenuLink">
      <li class="dropdown-submenu dropdown" *ngFor="let exams of surgeData">
        <a class="dropdown-item dropdown-toggle" *ngIf="exams?.url != '' && exams?.url != '/rank-predictor-jee'" href="{{exams?.url}}" target="_blank" style="color: #263643; cursor: pointer">{{exams?.exam}}</a>
        <a class="dropdown-item dropdown-toggle" *ngIf="exams?.url === ''" style="color: #263643; cursor: pointer">{{exams?.exam}}</a>
        <a class="dropdown-item" *ngIf="exams?.url === '/rank-predictor-jee'" href="{{exams?.url}}" style="color: #263643; cursor: pointer">{{exams?.exam}}</a>
        <ul class="dropdown-menu webmenuleft multi-dropdown" *ngIf="exams.data.length>0">
          <li *ngFor="let ncert of exams?.data" class="dropdown">
            <a *ngIf="ncert?.url != '/jee-main-question-paper-2024'" class="dropdown-item {{ncert.submenu.length>0 ? 'dropdown-toggle' : ''}} " href="{{ncert?.url}}" target="_blank" style="color: #263643; cursor: pointer">{{ncert?.name}}</a>
            <a *ngIf="ncert?.url === '/jee-main-question-paper-2024'" class="dropdown-item" href="{{ncert?.url}}" style="color: #263643; cursor: pointer">{{ncert?.name}}</a>
            <ul class="dropdown-menu webmenuleft premulti-dropdown" *ngIf="ncert.submenu.length>0">
              <li *ngFor="let url of ncert?.submenu">
                <a class="dropdown-item" href="{{url?.url}}" target="_blank" style="color: #263643; cursor: pointer">{{url?.name}}</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </li>
  <li class="nav-item"><a class="nav-link" href="https://infinitylearn.com/" (click)="teststage()">HOME</a></li>
  <!-- <li class="nav-item"><a class="nav-link" href="/jee">JEE</a></li>
  <li class="nav-item"><a class="nav-link" href="/neet"> NEET </a></li> -->

  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false" style="cursor: pointer">
      COURSES
    </a>
    <ul class="dropdown-menu dropdown webmenuleft" aria-labelledby="navbarDropdownMenuLink">
      <!-- <li class="dropdown-submenu">
        <a class="dropdown-item dropdown-toggle" style="color: #263643; cursor: pointer" (click)="redirection('jee')">JEE</a>
        <ul class="dropdown-menu webmenuleft">
          <li>
            <a class="dropdown-item" style="color: #263643; cursor: pointer" href="jee-13">Grade 13</a>
          </li>
          <li>
            <a class="dropdown-item" style="color: #263643; cursor: pointer" href="jee-12">Grade 12</a>
          </li>
          <li>
            <a class="dropdown-item" style="color: #263643; cursor: pointer" href="jee-11">Grade 11</a>
          </li>
        </ul>
      </li> -->
      <li class="dropdown-submenu">
        <a class="dropdown-item dropdown-toggle" style="color: #263643; cursor: pointer" href="https://infinitylearn.com/jee">JEE <span class="head_new">New</span></a>
        <ul class="dropdown-menu webmenuleft">
          <li>
            <a class="dropdown-item" style="color: #263643; cursor: pointer" href="https://infinitylearn.com/jee-class-13-online-course">Grade 13</a>
          </li>
          <li>
            <a class="dropdown-item" style="color: #263643; cursor: pointer" href="https://infinitylearn.com/jee-class-12-online-course">Grade 12</a>
          </li>
          <li>
            <a class="dropdown-item" style="color: #263643; cursor: pointer" href="https://infinitylearn.com/jee-class-11-online-course">Grade 11</a>
          </li>
        </ul>
      </li>
      <!-- <li class="dropdown-submenu"><a class="dropdown-item" style="color: #263643; cursor: pointer" (click)="redirection('jee-advanced')">JEE Advanced </a></li> -->
      <!-- <li class="dropdown-submenu">
        <a class="dropdown-item dropdown-toggle" style="color: #263643; cursor: pointer" (click)="redirection('neet')"> NEET </a>
        <ul class="dropdown-menu webmenuleft">
          <li>
            <a class="dropdown-item" style="color: #263643; cursor: pointer" href="/neet-repeater-liveclass">Grade 13</a>
          </li>
          <li>
            <a class="dropdown-item" style="color: #263643; cursor: pointer" href="neet-12">Grade 12</a>
          </li>
          <li>
            <a class="dropdown-item" style="color: #263643; cursor: pointer" href="neet-11">Grade 11</a>
          </li>
        </ul>
      </li> -->
      <li class="dropdown-submenu">
        <a class="dropdown-item dropdown-toggle" style="color: #263643; cursor: pointer" href="https://infinitylearn.com/neet"> NEET <span class="head_new">New</span> </a>
        <ul class="dropdown-menu webmenuleft">
          <li>
            <a class="dropdown-item" style="color: #263643; cursor: pointer" href="https://infinitylearn.com/neet-class-13-online-course">Grade 13</a>
          </li>
          <li>
            <a class="dropdown-item" style="color: #263643; cursor: pointer" href="https://infinitylearn.com/neet-class-12-online-course">Grade 12</a>
          </li>
          <li>
            <a class="dropdown-item" style="color: #263643; cursor: pointer" href="https://infinitylearn.com/neet-class-11-online-course">Grade 11</a>
          </li>
        </ul>
      </li>
      <li class="dropdown-submenu"><a class="dropdown-item" style="color: #263643; cursor: pointer" (click)="redirection('cuet')">CUET</a></li>
      <li class="dropdown-submenu">
        <a class="dropdown-item dropdown-toggle" style="color: #263643; cursor: pointer">FOUNDATION</a>
        <ul class="dropdown-menu webmenuleft">
          <li>
            <a class="dropdown-item" style="color: #263643; cursor: pointer" href="https://infinitylearn.com/foundation-course-for-class-8">Grade 8</a>
          </li>
          <li>
            <a class="dropdown-item" style="color: #263643; cursor: pointer" (click)="naviagteFuondation('9')">Grade 9</a>
          </li>
          <li>
            <a class="dropdown-item" style="color: #263643; cursor: pointer" (click)="naviagteFuondation('10')">Grade 10</a>
          </li>
        </ul>
      </li>
      <li class="dropdown-submenu"><a class="dropdown-item" style="color: #263643; cursor: pointer" (click)="redirection('engineering-entrance-exam-online-course')">Engineering Exams</a></li>
      <!-- <li class="dropdown-submenu">
        <a class="dropdown-item dropdown-toggle" style="color: #263643; cursor: pointer">CBSE</a>
        <ul class="dropdown-menu webmenuleft">
          <li *ngFor="let grade of Grades">
            <a class="dropdown-item" style="color: #263643; cursor: pointer" (click)="naviagteToCrash(grade.grade)">Grade {{grade.grade}}</a>
          </li>
        </ul>
      </li> -->
    </ul>
  </li>
  <li class="nav-item"><a class="nav-link" href="https://schools.infinitylearn.com/">SCHOOLS</a></li>

  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
      aria-expanded="false" style="cursor: pointer">
      SCORE
    </a>
    <ul class="dropdown-menu dropdown webmenuleft" aria-labelledby="navbarDropdownMenuLink">
      <li class="dropdown-submenu"><a class="dropdown-item" style="color: #263643; cursor: pointer"
        (click)="redirection('score-up')">Lucknow</a></li>
        <li class="dropdown-submenu"><a class="dropdown-item" style="color: #263643; cursor: pointer"
          (click)="redirection('score-bihar')">Patna</a></li>
      <li class="dropdown-submenu"><a class="dropdown-item" style="color: #263643; cursor: pointer"
          (click)="redirection('score-srichaitanyaschool')"> SC Techno Schools </a></li>
      <li class="dropdown-submenu"><a class="dropdown-item" style="color: #263643; cursor: pointer"
          (click)="redirection('score-srichaitanya')"> SC Colleges </a></li>
      <li class="dropdown-submenu"><a class="dropdown-item" style="color: #263643; cursor: pointer"
          (click)="redirection('score-knowledgehub')"> SC Knowledge Hub </a></li>
    </ul>
  </li>

  

  <!-- <li class="nav-item"><a class="nav-link" href="/score"> SCORE </a></li> -->
  <li class="nav-item">
    <a class="nav-link" (click)="navigateTosignupPage('signin')" href="javascript:void(0)" >
      Sign in
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link signup-btn" href="{{studentportal}}signup{{utm}}">
      Sign Up
    </a>
  </li>
  <!-- <li class="nav-item">
    <a class="nav-link" href="tel:9019846666"><i aria-hidden="true" class="fa fa-phone"></i></a>
  </li> -->
  <li class="nav-item">
    <a class="nav-link" href="tel:7996668865" (click)="callNow()"><img class="tocall" src="https://score-cdn-devinfinitylearn.s3.ap-south-1.amazonaws.com/Ilwebsite/Sankalp/call.png" alt=""></a>
  </li>
</ul>

import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/service/api.service';
declare let $: any;

@Component({
  selector: 'app-faculty',
  templateUrl: './faculty.component.html',
  styleUrls: ['./faculty.component.css'],
})
export class FacultyComponent implements OnInit {
  @Input() colorBlue:boolean = false;
  constructor(private _services: ApiService) {}

  faculty: any = [];
  faculty_active: any = [];

  ngOnInit(): void {

    setTimeout(function () {
      $('#masterclassfaculty').owlCarousel({
        loop: true,
        nav: true,
        autoplay: true,
        margin: 0,
        navText: [
          "<div class='nav-btn prev-slide'></div>",
          "<div class='nav-btn next-slide'></div>",
        ],
        responsive: {
          0: {
            items: 1,
          },
          576: {
            items: 1,
          },
          768: {
            items: 2,
          },
          992: {
            items: 3,
          },
        },
      });
    }, 2000);
  }
}

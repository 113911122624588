import { Component, OnInit, HostListener  } from '@angular/core';
import { FirebaseService } from 'src/app/service/firebase/firebase.service';
declare let $: any;
declare var jQuery: any;
@Component({
  selector: 'app-whatsappicon',
  templateUrl: './whatsappicon.component.html',
  styleUrls: ['./whatsappicon.component.css']
})
export class WhatsappiconComponent implements OnInit {
  whatsapp:boolean = false;
  buttomfloat:any;
  buttomfloatcbse:any;
  pathName:any;
  pages = ["/", "/neet", "/jee", "/jee-foundation",
            "/neet-foundation", "/cbse/6/full-course", "/cbse/7/full-course", "/cbse/8/full-course", "/cbse/9/full-course",
            "/cbse/10/full-course", "/cbse/11/full-course", "/cbse/12/full-course", "/score","/score-round1", "/jee/repeater",
            "/neet/repeater",];
  pageData:any;
  constructor(private firbaseService: FirebaseService) { }
  
  ngOnInit(): void {
    let pathname = window.location.pathname;
    this.pathName = window.location.pathname;
    let href = window.location.href.split("?")[1];
    console.log(pathname,'pathname');
    console.log(href,'href');
    if(href !="utm_source=BajajFinserv&utm_medium=AppInApp"){
      this.whatsapp = true;
    }
    if(!this.pages.includes(pathname)){
      this.buttomfloat = 'buttomfloat';
      this.buttomfloatcbse ="buttomfloatcbse";
    }
    this.firbaseService.getRemoteConfig("whatsapp_data").then((resp)=>{
      this.pageData = resp[window.location.pathname] || {};
      this.pageData.CssClassName = this.pageData?.isMobileScroll && window.innerWidth <481 ? `${this.pageData?.CssClassName} hide` : this.pageData?.CssClassName || 'hide';
    },(error)=>{
      console.log(error)
    })
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    let y = window.scrollY;
    let whatsapp = <HTMLElement>document.getElementById("whatsapp");
    if(this.pageData?.isMobileScroll && window.innerWidth <481){
      if (y >= this.pageData?.scroollLength) {
        whatsapp.className = "float show"
      } else {
        whatsapp.className = "float hide"
      }
    }
  }

}

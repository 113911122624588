export const environment = {
  production: false,
  isSelfLearnShow: true,
  isMigrationShow: false,
  isSqsShow:true,
  isScoreEnable:true,
  isStudentZoneShow: true,
  isRegisterAPI:true,
  name: "preprod", //case senstive dont change
  branch: 'preprod',
  studentportal: "https://student.devinfinitylearn.in/",
  routingDomain:".devinfinitylearn.in",
  unifiedweb:'https://unifiedpreprod.devinfinitylearn.in/',
  omsapi: "https://omsapi.devinfinitylearn.in",
  assessmentapi: "https://newasmapi.devinfinitylearn.in",
  newAsmApi: "https://eosapi.devinfinitylearn.in/",
  liveclassapi: "https://livepreprodapi.devinfinitylearn.in",
  webapi: "https://api.devinfinitylearn.in",
  scat: "https://srichaitanyascore.devinfinitylearn.in",
  SELF_LEARN_API: 'https://selflearnapi.devinfinitylearn.in/',
  uafapi: 'https://myactivity.devinfinitylearn.in/api/',
  gateWayUrl:'https://gatewayapi.devinfinitylearn.in/',
  gateWayOtpUrl:'https://otp.devinfinitylearn.in/',
  // razorpay_key: "rzp_test_cbwfDx2vukD9jT",
  razorpay_key: "rzp_test_12ntRUqV41EEBk",
  firebaseConfig : {
    apiKey: "AIzaSyAKIzu_pegviRDYGhVR7xqX9F2pY4N0M14",
    authDomain: "unified-infinity-learn.firebaseapp.com",
    databaseURL: "https://unified-infinity-learn-default-rtdb.firebaseio.com",
    projectId: "unified-infinity-learn",
    storageBucket: "unified-infinity-learn.appspot.com",
    messagingSenderId: "668051385949",
    appId: "1:668051385949:web:820adef20962928ddfe7b4",
    measurementId: "G-NVQRR77Z5K"
  },
  Bajaj: {
    showBajaj: true,
    min_amount: 1,
    max_amount: 2,
    Durations: [
      { tenor: 3, schemeId: "81526" },
      { tenor: 6, schemeId: "81527" },
    ],
  },
  scoreReport:{
    product_id:"0b2dd573-d842-4148-9464-d0cdc61274a6",
    dashboard_id: "4afdae37-68d5-4510-8542-6fe22b48dff5",
    tenant_id: "0b2dd573-d842-4148-9464-d0cdc61274a5",
    api: "https://analytics-staging.devinfinitylearn.in/dashboards-widgets"
  },  
  newWebsiteUrl: "https://loadtest.v2test.devinfinitylearn.in"
};
import { environment } from "src/environments/environment"

export const states = [
    {
      "abbreviation": "35",
      "name": "Andaman and Nicobar Islands"
    },
    {
      "abbreviation": "37",
      "name": "Andhra Pradesh"
    },
    {
      "abbreviation": "12",
      "name": "Arunachal Pradesh"
    },
    {
      "abbreviation": "18",
      "name": "Assam"
    },
    {
      "abbreviation": "10",
      "name": "Bihar"
    },
    {
      "abbreviation": "4",
      "name": "Chandigarh"
    },
    {
      "abbreviation": "22",
      "name": "Chattisgarh"
    },
    {
      "abbreviation": "25",
      "name": "Dadra and Nagar Haveli"
    },
    {
      "abbreviation": "DD",
      "name": "Daman and Diu"
    },
    {
      "abbreviation": "7",
      "name": "Delhi"
    },
    {
      "abbreviation": "30",
      "name": "Goa"
    },
    {
      "abbreviation": "24",
      "name": "Gujarat"
    },
    {
      "abbreviation": "6",
      "name": "Haryana"
    },
    {
      "abbreviation": "2",
      "name": "Himachal Pradesh"
    },
    {
      "abbreviation": "1",
      "name": "Jammu and Kashmir"
    },
    {
      "abbreviation": "20",
      "name": "Jharkhand"
    },
    {
      "abbreviation": "29",
      "name": "Karnataka"
    },
    {
      "abbreviation": "32",
      "name": "Kerala"
    },
    {
      "abbreviation": "31",
      "name": "Lakshadweep"
    },
    {
      "abbreviation": "23",
      "name": "Madhya Pradesh"
    },
    {
      "abbreviation": "27",
      "name": "Maharashtra"
    },
    {
      "abbreviation": "14",
      "name": "Manipur"
    },
    {
      "abbreviation": "17",
      "name": "Meghalaya"
    },
    {
      "abbreviation": "15",
      "name": "Mizoram"
    },
    {
      "abbreviation": "13",
      "name": "Nagaland"
    },
    {
      "abbreviation": "21",
      "name": "Odisha"
    },
    {
      "abbreviation": "34",
      "name": "Puducherry"
    },
    {
      "abbreviation": "3",
      "name": "Punjab"
    },
    {
      "abbreviation": "8",
      "name": "Rajasthan"
    },
    {
      "abbreviation": "11",
      "name": "Sikkim"
    },
    {
      "abbreviation": "33",
      "name": "Tamil Nadu"
    },
    {
      "abbreviation": "36",
      "name": "Telangana"
    },
    {
      "abbreviation": "16",
      "name": "Tripura"
    },
    {
      "abbreviation": "9",
      "name": "Uttar Pradesh"
    },
    {
      "abbreviation": "5",
      "name": "Uttarakhand"
    },
    {
      "abbreviation": "19",
      "name": "West Bengal"
    }
  ]

export const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
export const websiteFirebaseConfig = {
  apiKey: "AIzaSyBQ-lbsJFloIBTE1v4gig_MuUd9ftS4uBA",
  authDomain: "infinity-learn-website.firebaseapp.com",
  databaseURL: "https://infinity-learn-website-default-rtdb.firebaseio.com",
  projectId: "infinity-learn-website",
  storageBucket: "infinity-learn-website.appspot.com",
  messagingSenderId: "933031703939",
  appId: "1:933031703939:web:5271f2e67cda8c2f80b2b6"
  
}

export const apisDataCacheKeys: any = {
  billingDetails: 'billingDetails',
  scoreDetails: 'scoreDetails',
  selectedPackage: 'selectedPackage',
  selectedDuration: 'selectedDuration',
  userDetails: 'user_details_from_server',
  payments: 'subscription_payments',
  selectedExam:'selectedExam',
  selectedGrade:'selectedGrade',
  discountCode: 'discountCode',
  cartItemId:'cartItemId',
  entity: 'entity',
  completedDetails:'completedDetails',
  organization:'organization'
};
  export const unifiedWeb = environment.unifiedweb;
  export const studentportal = environment.studentportal;
  export const product_id = "300";
  export const tenant_id = "1"
<header class="header">
    <nav class="navbar navbar-expand-lg home-navbar">
      <div class="hmnavflex">
        <div>
          <a class="navbar-brand" href="https://infinitylearn.com/"><img src="/assets/images/logo.png" alt="Logo" class="headerlogo" loading="lazy"/></a>
        </div>
        <app-header-signup></app-header-signup>
      </div>
      <div id="navbarSupportedContent" class="collapse navbar-collapse">
        <app-header></app-header>
      </div>
      <div id="mySidenav" class="sidenav sidebar">
        <a href="javascript:void(0)" onclick="closeNav()" class="closebtn">×</a>
        <app-header-mob></app-header-mob>
      </div>
      <span onclick="openNav()" class="leftnav">☰ </span>
    </nav>
  </header>

<button >Back to home</button>
<div class="container text-center">
    <div class="row">
        <div class="col-lg-12">
            <figure>
                <img src="/assets/images/404notfound.png" class="foundimage" width="400px" alt="">
            </figure>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
          <button routerLink="/" type="submit" class="cbse-btn">Back to Home</button>
        </div>
    </div>
</div>

<footer class="footer-area">
    <app-footer></app-footer>
</footer>

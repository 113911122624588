    <!-- ========================= header start ========================= -->
    <header class="header careercolor">
      <nav class="navbar navbar-expand-lg home-navbar">
        <div class="hmnavflex">
          <div>
              <a class="navbar-brand" href="https://infinitylearn.com/"><img src="/assets/images/career/career-logo.png" alt="Logo" class="headerlogo"/></a>
          </div>
          <app-header-signup></app-header-signup>
      </div>
        <!-- <a class="navbar-brand" href="#"><img src="/assets/images/career/career-logo.png" alt="Logo"
            class="headerlogo" /></a> -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <app-header></app-header>
        </div>
        <div id="mySidenav" class="sidenav">
          <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
          <app-header-mob></app-header-mob>
        </div>
        <span class="leftnav" onclick="openNav()">&#9776; </span>
      </nav>
    </header>
    <!-- ========================= header end ========================= -->


    <!-- commingsoon section start -->
    <section class="comingsoon-sctn">
      <img src="/assets/images/teams/team-coming-soon.png" style="max-height: 600px;width:auto" class="img-fluid"
        alt="" />
    </section>
    <!-- commingsoon section ended -->

    <!-- ========================= Footer ========================= -->
    <footer class="careerfooter-area">
      <app-footer></app-footer>
    </footer>
